<template>
  <div class="body">
    <div class="container">
      <img src="../assets/logo.svg" alt="" />
      <p>
        Welcome to the Asset Portal. Please sign in or create a new asset portal if its your first time.<br /><br />
        If you've forgotten your password, click on "Forgot password" and a change-of-password link will be sent to your on-file email address.<br /><br />
        If you have any problems, email <a href="mailto:knoah@montanacorporate.com">knoah@montanacorporate.com</a><br />or call <a href="tel:406-204-2167">406-204-2167</a> for assistance.
      </p>
      <h1>Sign In</h1>
      <input v-model="email" type="text" @keydown.enter="signIn()" placeholder="Email" />
      <input v-model="password" type="password" @keydown.enter="signIn()" placeholder="Password" />
      <span class="errorMsg">{{ errorMsg }}</span>
      <button :class="{ loading: isLoading }" @click="signIn()"><span>Sign In To Your Asset Portal</span><img src="../assets/spinner.svg" alt="" /></button>
      <button class="signup" @click="goToSignup()">Create Your Asset Portal</button>
      <span class="forgot-password" @click="goToResetPassword()">Forgot password</span>
    </div>
  </div>
</template>

<script>
import { useServerConfig } from '@/composables/useServerConfig';

export default {
  name: 'Login',
  props: ['passedEmail', 'passedPassword'],
  setup() {
    const { serverAddress } = useServerConfig();
    return { serverAddress };
  },
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      errorMsg: '',
    };
  },
  methods: {
    goToSignup() {
      this.$emit('updateCredential', this.email, 'passedEmail');
      this.$emit('updateCredential', this.password, 'passedPassword');
      this.$router.push({ name: 'signup' });
    },
    goToResetPassword() {
      this.$emit('updateCredential', this.email, 'passedEmail');
      this.$emit('updateCredential', this.password, 'passedPassword');
      this.$router.push({ name: 'resetpassword' });
    },
    async signIn() {
      if (!this.email || !this.password) {
        this.errorMsg = 'Please fill out all the fields.';
        return;
      }
      this.isLoading = true;
      this.errorMsg = '';

      try {
        const response = await fetch(`${this.serverAddress}/accounts/login`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ uname: this.email, pw: this.password }),
        });
        if (!response.ok) {
          throw response.status;
        }
        const data = await response.json();
        if (!data.success) throw data.error;
        this.$router.push({ name: 'home' });
      } catch (error) {
        console.log(error);
        if (error == 'not verified') {
          this.errorMsg = 'Login Failed! Your email has not been verified.';
        } else if (error == 'incorrect credentials') {
          this.errorMsg = 'Login Failed! Your email or password are incorrect.';
        } else if (error == 'no account') {
          this.errorMsg = 'An account has not been setup for this email.';
        } else {
          this.errorMsg = 'Server Error! Unable to login to your account.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.email = this.passedEmail;
    this.password = this.passedPassword;
  },
};
</script>

<style lang="scss" scoped>
a {
  color: black;
}
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 130px);
  .container {
    width: 100%;
    max-width: 22rem;
    border: 1px solid #dcdcdc;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.5rem;
    }
    img {
      width: 12rem;
    }
    input {
      border: 1px solid #dcdcdc;
      padding: 0.8rem;
      margin: 0.5rem 0;
    }
    button {
      border: none;
      color: white;
      background: #eb9002;
      padding: 0.9rem;
      margin: 1rem 0;
      cursor: pointer;
      img {
        display: none;
      }
    }
    .loading {
      padding: 0.306rem;
      img {
        display: inline;
        width: 2rem;
      }
      span {
        display: none;
      }
    }
    .errorMsg {
      margin-bottom: -1rem;
      font-size: 0.8rem;
      height: 1.1rem;
      color: red;
      text-align: center;
    }
    .signup {
      margin-top: 0;
      background: white;
      color: black;
      border: 1px solid black;
    }
    .forgot-password {
      text-align: center;
      color: #585858;
      font-size: 0.8rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
