<template>
  <div class="body">
    <div class="container" v-if="!complete">
      <img src="../assets/logo.svg" alt="" />
      <h1>Create Asset Portal</h1>
      <p>Use the same email you used when starting your LLC to create and logon to your Asset Portal.</p>
      <input v-model="email" @keydown.enter="createAccount()" type="text" placeholder="Email" />
      <input v-model="password" @keydown.enter="createAccount()" type="password" placeholder="Password" />

      <div class="g-recaptcha" ref="recaptcha" style="padding-top: 0.5rem" data-sitekey="6Lf4hEAkAAAAADsDgz2ppL4bh6fZpUV0SqEu8tsG"></div>

      <span class="errorMsg">{{ errorMsg }}</span>
      <button :class="{ loading: isLoading }" @click="createAccount()"><span>Create Asset Portal</span><img src="../assets/spinner.svg" alt="" /></button>
      <span class="login" @click="goToLogin()">Already setup an asset portal</span>
    </div>
    <div class="container" v-if="complete">
      <img src="../assets/logo.svg" alt="" />
      <h1>Account Created</h1>
      <p>Your account has been created. You will receive an email to verify your account shortly. If you do not receive the email in a few minutes please check your spam and junk folders.</p>
    </div>
  </div>
</template>

<script>
import { useServerConfig } from '@/composables/useServerConfig';

export default {
  name: 'Sign up',
  props: ['passedEmail', 'passedPassword'],
  setup() {
    const { serverAddress } = useServerConfig();
    return { serverAddress };
  },
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      errorMsg: '',
      complete: false,
    };
  },
  methods: {
    goToLogin() {
      this.$emit('updateCredential', this.email, 'passedEmail');
      this.$emit('updateCredential', this.password, 'passedPassword');
      this.$router.push({ name: 'login' });
    },
    async createAccount() {
      if (!this.email || !this.password) {
        this.errorMsg = 'Please fill out all the fields.';
        return;
      }
      this.isLoading = true;
      this.errorMsg = '';

      //check captcha
      const captchaResponse = window.grecaptcha.getResponse();
      if (!captchaResponse) {
        this.errorMsg = 'Please complete the captcha.';
        this.isLoading = false;
        return;
      }

      try {
        const response = await fetch(`${this.serverAddress}/accounts/createnewaccount`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ uname: this.email, pw: this.password, captcha: captchaResponse }),
        });
        if (!response.ok) {
          throw response.status;
        }
        const data = await response.json();
        if (!data.success) throw data.error;
        console.log('success!');
        this.complete = true;
      } catch (error) {
        console.log(error);
        if (error == 'bad password') {
          this.errorMsg = 'Error! Using unsupported characters in password.';
        } else if (error == 'bad username') {
          this.errorMsg = 'Error! Using unsupported characters in username.';
        } else if (error == 'duplicate account') {
          this.errorMsg = 'Error! There is already an account with this email.';
        } else {
          this.errorMsg = 'Server Error! Unable to create your account.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.email = this.passedEmail;
    this.password = this.passedPassword;
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 130px);
  .container {
    width: 100%;
    max-width: 22rem;
    border: 1px solid #dcdcdc;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.5rem;
    }
    img {
      width: 12rem;
    }
    input {
      border: 1px solid #dcdcdc;
      padding: 0.8rem;
      margin: 0.5rem 0;
    }
    button {
      border: none;
      color: white;
      background: #eb9002;
      padding: 0.9rem;
      margin: 1rem 0;
      cursor: pointer;
      img {
        display: none;
      }
    }
    .loading {
      padding: 0.306rem;
      img {
        display: inline;
        width: 2rem;
      }
      span {
        display: none;
      }
    }
    .errorMsg {
      margin-bottom: -1rem;
      font-size: 0.8rem;
      height: 1.1rem;
      color: red;
      text-align: center;
    }
    .login {
      text-align: center;
      color: #585858;
      font-size: 0.8rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
