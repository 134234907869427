<template>
  <div class="page" @click="showAccountMenu = showDontSeeAsset = false">
    <div v-if="showActivePopup" class="modal-overlay"></div>
    <div v-if="showActivePopup" class="modal-container">
      <div class="modal__header">
        <img class="modal__header-icon" src="../assets/question.svg" alt="" />
        <h5 class="modal__header-title">{{ activePopupInfo.activating ? 'Activate Asset' : 'Inactive Asset' }}</h5>
      </div>
      <p class="modal__content" v-if="activePopupInfo.activating">Click <b>activate</b> to <u>resume</u> receiving renewal notifications for this asset or click <b>cancel</b> to continue <u>not</u> receiving renewal notifications for this asset.</p>
      <p class="modal__content" v-else>Making this asset inactive will <u>not</u> delete it but rather make it less conspicuous and no license renewal information will be forwarded or processed. At any time, you can make it active again by simply moving the slider to <u>Active</u>.</p>
      <div class="modal__actions">
        <button class="modal__actions-button modal__actions-button--secondary" @click="showActivePopup = false">Cancel</button>
        <button v-if="activePopupInfo.activating" class="modal__actions-button modal__actions-button--primary" @click="activateAsset()">Activate</button>
        <button v-else class="modal__actions-button modal__actions-button--primary" @click="deactivateAsset()">Inactivate</button>
      </div>
    </div>

    <div class="page-container">
      <div class="header">
        <h1>My Company Assets</h1>
        <div class="header__menu">
          <img class="header__menu-icon" src="../assets/menu.svg" @click.stop="showAccountMenu = !showAccountMenu" alt="" />
          <ul v-show="showAccountMenu" class="header__menu-dropdown">
            <li class="header__menu-dropdown-item" @click="logout">Logout</li>
          </ul>
        </div>
      </div>

      <p class="disclaimer">
        If you notice any errors in the data presented, click
        <a class="disclaimer__link" href="mailto:support@montanacorporate.com">HERE</a>
        to send an email to Montana Corporate and we'll determine if the error can be rectified and if so, how to do it.
      </p>

      <div class="section" v-if="!error">
        <h2 class="section__title">LLCS</h2>
        <div class="grid-container">
          <div class="card" v-for="llc in llcs" :key="llc.id">
            <div class="card__content">
              <div class="card__header">
                <img class="card__header-icon" src="../assets/llc-icon.svg" alt="" />
                <span class="card__header-title">{{ llc.name }}</span>
              </div>
              <div class="card__body">
                <b>{{ llc.membername }}</b
                ><br />
                {{ llc.address }}<br />
                {{ llc.city }}, {{ llc.state }} {{ llc.zip }}<br />
                {{ formatPhoneNumber(llc.phone) }}<br />
                {{ llc.email }}

                <span class="card__member" v-for="member in filteredMembers(llc.id)" :key="member.id">
                  <br /><br />
                  <b>{{ member.name }}</b
                  ><br />
                  {{ formatPhoneNumber(member.phone) }}<br />
                  {{ member.email }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="!llcs.length" class="card__empty">
            <h3>
              There are no LLCs for this account.<br />
              <a href="https://montanacorporate.com/formllcfaq">Create new LLC here.</a>
            </h3>
          </div>
        </div>
      </div>

      <div class="section" v-if="!error">
        <h2 class="section__title">
          ASSETS
          <span class="section__title-link" @click.stop="showDontSeeAsset = true">
            Dont see an asset?
            <div v-if="showDontSeeAsset" class="section__title-link-popup">
              <p>
                Only asset data that was submitted thru the Vehicle Licensing page of the Montana Corporate website will show here.<br /><br />
                Asset data that was submitted by dealing directly with a Montana Corporate agent for the licensing process will not.<br /><br />
                We keep all your data secure and, if you need it, can send you any information or forward documents about any asset we helped you acquire.
              </p>
            </div>
          </span>
        </h2>

        <div class="grid-container">
          <div class="card" v-for="asset in assets" :key="asset.id">
            <div v-if="asset.inactive" class="card--inactive"></div>
            <div class="card__content">
              <div class="card__header">
                <img class="card__header-icon" :src="getAssetIcon(asset.type)" alt="" />
                <span class="card__header-title">
                  {{ asset.year }} {{ asset.make }}<br />
                  <span class="card__header-title-subtitle">{{ asset.model }}</span>
                </span>
                <div class="status-toggle">
                  <img v-if="!asset.inactive" @click="setActivePopup(asset.id, false)" src="../assets/active.svg" alt="" />
                  <img v-else @click="setActivePopup(asset.id, true)" src="../assets/inactive.svg" alt="" />
                  <p class="status-toggle__label">
                    {{ asset.inactive ? 'Inactive' : 'Active' }}
                  </p>
                </div>
              </div>

              <div class="asset-details">
                <span class="asset-details__row">
                  <span class="asset-details__label">OWNER LLC</span>
                  <span class="asset-details__value" :title="asset.name">{{ asset.name }}</span>
                </span>
                <span class="asset-details__row">
                  <span class="asset-details__label">SUBMITTED</span>
                  <span class="asset-details__value">{{ formatDate(asset.datesubmitted) }}</span>
                </span>
                <span class="asset-details__row">
                  <span class="asset-details__label">PLATE NUMBER</span>
                  <span class="asset-details__value">{{ asset.licenseplate }}</span>
                </span>
                <span class="asset-details__row">
                  <span class="asset-details__label">RENEWAL DEADLINE</span>
                  <span class="asset-details__value">{{ ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][asset.renewaldeadline] }}</span>
                </span>
                <span class="asset-details__row">
                  <span class="asset-details__label">MILEAGE</span>
                  <span class="asset-details__value">{{ asset.mileage }}</span>
                </span>
                <span class="asset-details__row">
                  <span class="asset-details__label">VIN</span>
                  <span class="asset-details__value">{{ getNumber(asset) }}</span>
                </span>
              </div>

              <div class="card__section">
                <h5 class="card__section-title">OPTIONS</h5>
                <div class="card__section-content">
                  <span v-if="asset.regexpedite == 'RegOnly'">Expedite Registration</span>
                  <span v-if="asset.regexpedite == 'RegAndTitle'">Expedite Registration and Title</span>
                  <span v-if="asset.tempplate">Temp Plate</span>
                  <span v-if="asset.tempplaterush">Temp Plate RUSH</span>
                  <span v-if="asset.overnightdecal">Overnight Decal</span>
                  <span v-if="asset.foreignreg">Foreign Registration</span>
                  <span v-if="asset.breakbond">Break/Bond</span>
                  <span v-if="noOptionsSelected(asset)"> - </span>
                </div>
              </div>

              <div class="card__section">
                <h5 class="card__section-title">HISTORY</h5>
                <span class="card__section-content">
                  {{ asset.timeline ? asset.timeline.replace(/``lb/g, '\n') : ' - ' }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="!assets.length" class="card__empty">
            <h3>
              There are no assets for this account.<br />
              <a href="https://montanacorporate.com/licensing">License new asset here.</a>
            </h3>
          </div>
        </div>
      </div>

      <div class="section" v-if="!error">
        <h2 class="section__title" @click="fetchInvoices()">RENEWALS</h2>

        <div class="table">
          <div class="table__header">
            <div class="table__header-cell"></div>
            <div class="table__header-cell">ASSET</div>
            <div class="table__header-cell">DEADLINE</div>
            <div class="table__header-cell">STATUS</div>
          </div>

          <div class="table__body">
            <div class="table__row" v-for="renewal in sortedRenewals" :key="renewal.id">
              <div class="table__cell">
                <button v-if="!renewal.approved" @click="approveRenewal(renewal.id)">APPROVE</button>
                <span v-else>APPROVED</span>
              </div>
              <div class="table__cell">{{ renewal.assetYear }} {{ renewal.make }} {{ renewal.model }}</div>
              <div class="table__cell">{{ ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][renewal.renewalDeadline] }} {{ renewal.year }}</div>
              <div class="table__cell">
                <span v-if="renewal.complete">Completed</span>
                <span v-else-if="renewal.approved">Processing</span>
                <span v-else>Waiting for approval</span>
              </div>
            </div>

            <div v-if="!renewals.length" class="table__row">
              <div class="table__cell" colspan="4" style="text-align: center; width: 100%">No vehicle renewals</div>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="section">
        <h2 class="section__title">Invoices</h2>
        <button class="section__button" @click="fetchInvoices()">Get Invoices</button>
      </div>-->

      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { useServerConfig } from '@/composables/useServerConfig';
import { useGetInvoices } from '@/composables/useGetInvoices';

export default {
  name: 'Home',
  components: {},
  setup() {
    const { serverAddress } = useServerConfig();
    const { fetchInvoices } = useGetInvoices();
    return { serverAddress, fetchInvoices };
  },
  data() {
    return {
      llcs: [],
      assets: [],
      members: [],
      renewals: [],
      showAccountMenu: false,
      showDontSeeAsset: false,
      showActivePopup: false,
      activePopupInfo: {
        id: null,
        activating: false,
      },
      error: '',
    };
  },
  computed: {
    isShowingPopup() {
      let popupShowing = false;
      this.assets.forEach((asset) => {
        if (asset.showActivePopup) popupShowing = true;
      });
      return popupShowing;
    },
    sortedRenewals() {
      return [...this.renewals].sort((a, b) => a.year - b.year);
    },
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await fetch(`${this.serverAddress}/resources/getuserinfo`, { credentials: 'include', method: 'POST' });
        if (!response.ok) {
          throw response.status;
        }
        const data = await response.json();
        if (!data.success) throw data.error;
        this.llcs = data.llcs;
        this.assets = data.assets;
        this.members = data.members;
        this.renewals = data.renewals;
      } catch (error) {
        console.log(error);
        if (error == '401') {
          this.$router.push({ name: 'login' });
        } else {
          this.error = 'There was an error getting your account information. Please try refreshing the page.';
          console.log('Server Error!');
        }
      }
    },
    setActivePopup(assetid, activating) {
      this.activePopupInfo.id = assetid;
      this.activePopupInfo.activating = activating;
      this.showActivePopup = true;
    },
    activateAsset() {
      const index = this.assets.findIndex((asset) => asset.id == this.activePopupInfo.id);
      if (index < 0) return;
      this.assets[index].inactive = false;
      this.showActivePopup = false;
      this.updateActiveAsset(this.activePopupInfo.id, false);
    },
    deactivateAsset() {
      const index = this.assets.findIndex((asset) => asset.id == this.activePopupInfo.id);
      if (index < 0) return;
      this.assets[index].inactive = true;
      this.showActivePopup = false;
      this.updateActiveAsset(this.activePopupInfo.id, true);
    },
    async updateActiveAsset(assetId, isActive) {
      try {
        const response = await fetch(`${this.serverAddress}/resources/updateactiveasset`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ assetid: assetId, inactive: isActive }),
        });
        if (!response.ok) {
          throw response.status;
        }
        const data = await response.json();
        if (!data.success) throw data.error;
        console.log('set active state successfully');
      } catch (error) {
        console.log(error);
        if (error == '401') {
          this.$router.push({ name: 'login' });
        } else {
          console.log('did not update active state successfully!');
          const index = this.assets.findIndex((asset) => asset.id == assetId);
          if (index >= 0) {
            this.assets[index].inactive = !isActive;
            alert("There was an error while trying to update your asset's active state.");
          }
        }
      }
    },
    formatPhoneNumber(phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return phoneNumberString;
    },
    filteredMembers(llcid) {
      let filteredMembers = [];
      this.members.filter((member) => {
        if (member.llcid == llcid) {
          filteredMembers.push(member);
        }
      });
      filteredMembers = filteredMembers.sort((a, b) => {
        if (a.membernumber > b.membernumber) return 1;
        else if (a.membernumber < b.membernumber) return -1;
        else return 0;
      });
      return filteredMembers;
    },
    getAssetIcon(assetType) {
      let img = '';

      if (assetType == 'carTruck') {
        img = 'car';
      } else if (assetType == 'boat') {
        img = 'boat';
      } else if (assetType == 'plane') {
        img = 'plane';
      } else if (assetType == 'motorhome') {
        img = 'motorhome';
      } else if (assetType == 'atv' || assetType == 'motorcycle') {
        img = 'atv';
      } else if (assetType == 'trailer') {
        img = 'trailer';
      } else {
        img = 'car';
      }

      var images = require.context('../assets/', false, /\.svg$/);
      return images('./' + img + '.svg');
    },
    getNumber(asset) {
      if (asset.vin) {
        return '*' + asset.vin;
      } else if (asset.hid) {
        return '*' + asset.hid;
      } else if (asset.nnumber) {
        return asset.nnumber;
      }
    },
    noOptionsSelected(asset) {
      if (!asset.regexpedite || !asset.tempplate || !asset.tempplaterush || !asset.overnightdecal || !asset.foreignreg || !asset.breakbond) {
        return true;
      } else {
        return false;
      }
    },
    formatDate(rawDate) {
      let d = new Date(rawDate);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
    },
    async logout() {
      try {
        await fetch(`${this.serverAddress}/accounts/logout`, { credentials: 'include', method: 'POST' });
        this.$router.push({ name: 'login' });
      } catch (error) {
        console.log(error);
        if (error == '401') {
          this.$router.push({ name: 'login' });
        } else {
          this.error = 'There was an error logging out. Please try again.';
          console.log('Server Error!');
        }
      }
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    approveRenewal(renewalId) {
      const renewal = this.renewals.find((renewal) => renewal.id === renewalId);
      if (!renewal) {
        console.log('Error approving renewal: renewal not found');
        return;
      }

      location.href = `https://montanacorporate.com/renewal?id=${renewal.id}&key=${renewal.authkey}`;
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
// Layout
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 100px);

  @media only screen and (max-width: 520px) {
    min-height: calc(100vh + 100px);
  }
}

.page-container {
  width: 100%;
  max-width: 75rem;
  padding: 2rem 3rem;
  margin-bottom: 7rem;

  @media only screen and (max-width: 520px) {
    padding: 1rem 1.5rem;
  }
}

// Header
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__menu {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;

    &-icon {
      width: 3rem;
      margin-left: 1rem;
      margin-top: 0.5rem;
      cursor: pointer;
    }

    &-button {
      border: none;
      border-radius: 4rem;
      color: white;
      background: #464765;
      height: 3rem;
      padding: 0 2.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      margin-top: 0.5rem;
    }

    &-dropdown {
      list-style: none;
      position: absolute;
      top: 2.8rem;
      background: #ebebeb;
      border-radius: 0.3rem;
      right: 0;
      z-index: 100;
      padding: 0;

      &-item {
        border-radius: 0.3rem;
        padding: 0.5rem 1.5rem;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #ccc;
        }
      }
    }
  }
}

// Content Sections
.section {
  margin-top: 3rem;

  &__title {
    font-size: 1.3rem;

    &-link {
      color: #45a5fe;
      font-size: 1rem;
      font-weight: normal;
      padding-left: 1rem;
      cursor: pointer;
      position: relative;

      &:hover {
        text-decoration: underline;
      }

      &-popup {
        z-index: 5;
        max-width: 30rem;
        width: calc(95vw - 9rem);
        position: absolute;
        top: 2rem;
        left: -3rem;
        color: black;
        padding: 2rem;
        background: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

// Grid Layout
.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

// Cards
.card {
  background: radial-gradient(50% 50% at 50% 50%, #f9f9f9 0%, #f4f4f4 100%);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 2rem;
  max-width: 30rem;
  min-width: 25rem;
  box-sizing: border-box;
  position: relative;

  @media only screen and (max-width: 520px) {
    min-width: 100%;
  }

  &--inactive {
    background: rgb(240, 240, 240, 76%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    font-size: 0.8rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-icon {
      width: 2.5rem;
    }

    &-title {
      margin-left: 0.7rem;
      font-size: 1.2rem;
      font-weight: bold;
      color: #303030;
      line-height: 1;

      &-subtitle {
        font-size: 0.95rem;
        font-weight: 600;
      }
    }
  }

  &__body {
    margin-left: 0.5rem;
  }

  &__section {
    margin-left: 0.5rem;

    &-title {
      margin-top: 0;
      margin-bottom: 0.3rem;
      font-size: 0.8rem;
    }
  }
}

// Asset Status Toggle
.status-toggle {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  align-items: flex-end;

  img {
    width: 2.5rem;
  }

  &__label {
    font-size: 0.8rem;
    margin: 0;
    margin-right: 0.2rem;
  }
}

// Asset Details
.asset-details {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  width: 100%;

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
  }

  &__label {
    font-weight: 600;
    font-size: 0.7rem;
    width: 8rem;
  }

  &__value {
    flex: 1;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// Modal
.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: transparent;
  }

  &-container {
    z-index: 4;
    position: fixed;
    height: 13rem;
    width: 25rem;
    max-width: 75vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: white;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: space-around;

    @media only screen and (max-width: 456px) {
      height: 16rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    &-icon {
      width: 2.5rem;
    }

    &-title {
      font-size: 1.6rem;
      font-weight: normal;
      padding-left: 1rem;
      margin: 0;
      margin-top: 0.4rem;
    }
  }

  &__content {
    padding-left: 3.5rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 0;

    &-button {
      margin-left: 0.5rem;
      padding: 0.6rem 1rem;
      border: none;
      text-transform: uppercase;
      border-radius: 0.25rem;
      color: white;
      cursor: pointer;

      &--secondary {
        background: #aba9a9;
      }

      &--primary {
        background: #0065ae;
      }
    }
  }
}

// Utility Classes
.error-message {
  margin: 2rem;
  font-size: 1.5rem;
  color: red;
}

.disclaimer {
  max-width: 50rem;

  &__link {
    text-decoration: none;
    color: black;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Table Styles
.table {
  width: 100%;
  margin-top: 1rem;

  &__header {
    display: flex;
    padding: 0.2rem;

    &-cell {
      font-weight: bold;
      font-size: 0.9rem;

      &:first-child {
        width: 7rem;
        min-width: 7rem;
      }

      &:not(:first-child) {
        flex: 1;
        max-width: 15rem;
      }

      @media only screen and (max-width: 520px) {
        font-size: 0.8rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    padding: 0.2rem;
    align-items: center;
    border-top: 1px solid #e5e7ed;

    &:last-child {
      border-bottom: 1px solid #e5e7ed;
    }
  }

  &__cell {
    font-size: 0.9rem;

    &:first-child {
      width: 7rem;
      min-width: 7rem;
    }

    &:not(:first-child) {
      flex: 1;
      max-width: 15rem;
    }

    @media only screen and (max-width: 520px) {
      font-size: 0.8rem;
    }

    button {
      background: #0065ae;
      color: white;
      border: none;
      padding: 0.3rem 1rem;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.8rem;

      @media only screen and (max-width: 520px) {
        font-size: 0.7rem;
        padding: 0.4rem 1.2rem;
      }
    }
  }
}
</style>
