<template>
  <div class="body">
    <div class="container" v-if="!complete && !fetchErrorMsg">
      <img src="../assets/logo.svg" alt="" />
      <h1>Set New Password</h1>
      <input v-model="password" @keydown.enter="setPassword()" type="password" placeholder="New Password" />
      <span class="errorMsg">{{ errorMsg }}</span>
      <button :class="{ loading: isLoading }" @click="setPassword()"><span>Set New Password</span><img src="../assets/spinner.svg" alt="" /></button>
    </div>
    <div class="container" v-else-if="fetchErrorMsg">
      <img src="../assets/logo.svg" alt="" />
      <h1>Password Reset Failed</h1>
      <p>{{ fetchErrorMsg }}</p>
      <button @click="tryAgain()">Try Again</button>
    </div>
    <div class="container" v-if="complete">
      <img src="../assets/logo.svg" alt="" />
      <h1>Account Password Reset!</h1>
      <button @click="goToLogin()">Login</button>
    </div>
  </div>
</template>

<script>
import { useServerConfig } from '@/composables/useServerConfig';

export default {
  name: 'Create Password',
  setup() {
    const { serverAddress } = useServerConfig();
    return { serverAddress };
  },
  data() {
    return {
      password: '',
      errorMsg: '',
      fetchErrorMsg: '',
      isLoading: false,
      complete: false,
    };
  },
  methods: {
    goToLogin() {
      this.$emit('updateCredential', this.$route.query.username, 'passedEmail');
      this.$router.push({ name: 'login' });
    },
    tryAgain() {
      this.fetchErrorMsg = '';
      this.complete = false;
    },
    async setPassword() {
      if (!this.password) {
        this.errorMsg = 'Please fill out all the fields.';
        return;
      }
      this.isLoading = true;
      this.errorMsg = '';

      try {
        const response = await fetch(`${this.serverAddress}/accounts/resetpw`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ pw: this.password, uname: this.$route.query.username, key: this.$route.query.key }),
        });
        if (!response.ok) {
          throw response.status;
        }
        const data = await response.json();
        if (!data.success) throw data.error;
        this.complete = true;
      } catch (error) {
        console.log(error);
        if (error == 'bad password') {
          this.errorMsg = 'Error! Using unsupported characters in password.';
        } else {
          this.fetchErrorMsg = 'There was an error resetting your password.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.email = this.passedEmail;
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 130px);
  .container {
    width: 100%;
    max-width: 22rem;
    border: 1px solid #dcdcdc;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.5rem;
    }
    img {
      width: 12rem;
    }
    input {
      border: 1px solid #dcdcdc;
      padding: 0.8rem;
      margin: 0.5rem 0;
    }
    button {
      border: none;
      color: white;
      background: #eb9002;
      padding: 0.9rem;
      margin: 1rem 0;
      cursor: pointer;
      img {
        display: none;
      }
    }
    .loading {
      padding: 0.306rem;
      img {
        display: inline;
        width: 2rem;
      }
      span {
        display: none;
      }
    }
    .errorMsg {
      margin-bottom: -1rem;
      font-size: 0.8rem;
      height: 1.1rem;
      color: red;
      text-align: center;
    }
    .login {
      text-align: center;
      color: #585858;
      font-size: 0.8rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
