import { ref } from 'vue';
import { useServerConfig } from './useServerConfig';

export function useGetInvoices() {
  const { serverAddress } = useServerConfig();
  const invoices = ref([]);
  const error = ref(null);
  const loading = ref(false);

  const fetchInvoices = async () => {
    loading.value = true;
    error.value = null;
    try {
      const response = await fetch(`${serverAddress.value}/qb/getinvoices`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw response.status;
      }
      const data = await response.json();
      if (!data.success) throw data.error;
      invoices.value = data.invoices;
    } catch (err) {
      console.log(err);
      if (err == '401') {
        // Handle unauthorized
      } else {
        error.value = 'There was an error fetching invoices. Please try again.';
      }
    } finally {
      loading.value = false;
    }
  };

  return {
    invoices,
    error,
    loading,
    fetchInvoices,
  };
}
